/* eslint-disable no-unreachable */
import "../../style.css";
import { Card, CircularProgress, Grid, Icon, InputAdornment, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormAction from "redux-react/actions/formAction";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import lod_ from "lodash";
import SettingsActions from "redux-react/actions/settingsActions";

const ChannelBox = ({ channel, selectChannel }) => {
	return (
		<MDBox
			className="channelBoxContainer"
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="space-between"
			borderRadius="lg"
			p={2}
			sx={{
				height: "100%",
				width: "100%"
			}}
			onClick={() => {
				selectChannel(channel);
			}}
		>
			<MDBox
				m={2}
				component="img"
				src={channel.logoURL}
				alt={channel.logoURL}
				sx={{
					width: "50%",
					height: "auto",
					objectFit: "contain",
					mb: 1
				}}
			/>
			<MDBox>
				<MDTypography variant="h5" align="center">
					{channel.name}
				</MDTypography>
			</MDBox>
		</MDBox>
	);
};

/**
 * Step 1: Selected the profile type
 */
const Step1ChannelChoice = ({ validStep, selectChannel, dictionaryChannelName }) => {
	const profile = useSelector(selectCurrentProfile);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [availableChannels, setAvailableChannels] = useState([]);
	const [listTag, setListTag] = useState([]);

	const [filter, setFilter] = useState("");

	const loadChannels = () => {
		dispatch(
			FormAction.getItemsFromCollection(
				"channel",
				{
					query: {
						documentType: "channel",
						active: true,
						assistantID: {
							$in: [null, profile.assistantID]
						}
					},
					catalog: "CHANNEL"
				},
				res => {
					let channels = res?.items ?? [];
					setAvailableChannels(channels);
					setLoading(false);
				}
			)
		);
	};

	useEffect(() => {
		setAvailableChannels([]);
		loadChannels();

		dispatch(
			SettingsActions.getDictionary(dictionaryChannelName, res => {
				const commonDictionary = res.dictionary ?? {};

				if (
					!lod_.isNil(commonDictionary?.items) &&
					!lod_.isNil(commonDictionary?.items?.listTag) &&
					!lod_.isNil(commonDictionary?.items?.listTag?.codes)
				) {
					setListTag(commonDictionary.items.listTag.codes);
				} else {
					setListTag([]);
				}
			})
		);
	}, []);

	if (loading)
		return (
			<MDBox
				display="flex"
				alignItems="center"
				justifyContent="center"
				sx={{
					height: "100%",
					width: "100%"
				}}
			>
				<CircularProgress color="info" />
			</MDBox>
		);

	return (
		<MDBox sx={{ height: "100%", width: "100%", position: "relative" }}>
			<MDBox
				display="flex"
				justifyContent="center"
				alignItems="center"
				style={{
					position: "sticky",
					top: 0
				}}
				bgColor="white"
			>
				<MDBox
					pt={2}
					pb={1}
					style={{
						position: "sticky",
						top: 0,
						width: "50%"
					}}
				>
					<MDInput
						fullWidth
						label={i18n.t("CHANNEL.searchCanal")}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon fontSize="medium" color="info">
										search
									</Icon>
								</InputAdornment>
							)
						}}
						value={filter}
						onChange={e => setFilter(e.target.value)}
					/>
				</MDBox>
			</MDBox>
			<MDBox style={{ paddingBottom: "1%" }}>
				{!lod_.isNil(listTag) &&
					!lod_.isEmpty(listTag) &&
					listTag
						.sort((a, b) => a.label.localeCompare(b.label))
						.map(
							(channelCat, index) =>
								!lod_.isEmpty(
									availableChannels.filter(
										channel =>
											channel.name.toLowerCase().includes(filter.toLowerCase()) &&
											channel.tag === channelCat.value
									)
								) && (
									<Card style={{ marginBottom: "1%" }} index={index}>
										<MDBox p={2}>
											<Typography variant="h4"> {channelCat.label} </Typography>
											<MDBox style={{ display: "flex", flexWrap: "wrap" }}>
												{availableChannels
													.filter(
														channel =>
															channel.name.toLowerCase().includes(filter.toLowerCase()) &&
															channel.tag === channelCat.value
													)
													.sort((a, b) => a.name.localeCompare(b.name))
													.map((channel, index) => (
														<MDBox style={{ width: "8%" }} key={index}>
															<ChannelBox
																channel={channel}
																selectChannel={selectChannel}
																onClick={() => {
																	validStep();
																}}
															/>
														</MDBox>
													))}
											</MDBox>
										</MDBox>
									</Card>
								)
						)}
			</MDBox>
		</MDBox>
	);
};

export default Step1ChannelChoice;
