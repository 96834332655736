/* eslint-disable no-restricted-syntax */
/**
 * #######################################################@
 *
 * FORMS page
 *
 * #######################################################@
 */
import "./style.css";
import { socket } from "redux-react/middleware/ws";

import {
	Box,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	IconButton,
	Tooltip
} from "@mui/material";
import FormActions from "redux-react/actions/formAction";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import lod_ from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import { useForm } from "hooks/useForm";
import SkillActions from "redux-react/actions/skillActions";

import PannelCode from "./Components/RightPannel/PannelCode";
import PannelDynamicList from "./Components/RightPannel/PannelDynamicList";
import OrderFormDictionaryChild from "./Components/OrderFormDictionaryChild";
import PannelDictionary from "./Components/RightPannel/PannelDictionary";
import AccessResource from "./Components/AccessResource";
import ApiAccess from "./Components/ApiAccess";

let targetForm = "";

export default function FormDictionaryDialog({
	route,
	open,
	handleCloseDialog,
	handleSave,
	valuesDictionary,
	formBuildDictionary,
	contextDictionary,
	PIaccess,
	isEmpty,
	target,
	selectedId,
	routeDict,
	flagAccount,
	forceEdit = false,
	regexEmail,
	listAPIs
}) {
	const { user, profile } = useSelector(state => state);

	let validate = () => {
		const errors = {};
		// validateData();
		return errors;
	};

	const [PI, setPI] = useState(PIaccess);
	const [formDictionary, setFormDictionary] = useState(formBuildDictionary);
	const [customErrors, setCustomErrors] = useState({});
	const [hasErrors, setHasErrors] = useState(false);
	const [confirmHasPI, setConfirmHasPI] = useState(false);
	const dispatch = useDispatch();
	const [customUnique, setCustomUnique] = useState({});
	const [loadingValidate, setLoadingValidate] = useState(false);
	const [rolesProfileUser, setRolesProfileUser] = useState([]);
	const [listSkills, setListSkills] = useState([]);
	const [listProfils, setListProfils] = useState([]);

	let { values, errors, setValues, handleInputChange } = useForm(valuesDictionary, true, validate);

	/**
	 * Check if there is errors in form to enable/disable validate button
	 */
	useEffect(() => {
		if (lod_.isEmpty(customErrors)) {
			setHasErrors(false);
		} else {
			setCustomErrors({ ...customErrors });
			setHasErrors(true);
		}
	}, [customErrors, rolesProfileUser]);

	function setListNotEmpty() {
		if (routeDict === "dictionary" && isEmpty) {
			return [];
		} else if (routeDict === "dictionary" && lod_.isArray(values?.codes) && !isEmpty) {
			return values?.codes;
		} else {
			return [];
		}
	}

	const [listCodes, setListCodes] = useState(setListNotEmpty());

	/**
	 * Setup form with get different config/info for route target
	 */
	useEffect(() => {
		targetForm = target;
		if (targetForm === "profile") {
			const onSuccess = res => {
				setListSkills(res.skills);
			};
			dispatch(SkillActions.getAllSkills(onSuccess));
		}
		if (targetForm === "skill") {
			const onSuccess = res => {
				setListSkills(res.profiles);
			};

			dispatch(SkillActions.getProfils("profile", profile?.system, onSuccess));
		}
	}, [route]);

	/**
	 * Change value with different condition and sand error if not agree with condition
	 */
	useEffect(() => {
		if (routeDict === "dictionary") {
			let e = { target: { name: "whitelist", value: [] } };
			handleInputChange(e);
			if (["boolean", "code", "level", "color"].includes(values?.type)) {
				let cloneValues = lod_.cloneDeep(values);
				cloneValues.isDynamic = false;
				delete cloneValues?.whitelistDynamic;
				setValues(cloneValues);
			}

			let e2 = { target: { name: "isDynamic", value: false } };
			handleInputChange(e2);

			if (["labels", "actions"].includes(values?.type)) {
				let cloneValues = lod_.cloneDeep(values);
				lod_.set(cloneValues, "isDynamic", true);
				lod_.set(cloneValues, "whitelistDynamic", {});
				setValues(cloneValues);
			}
		}
	}, [values?.type]);

	useEffect(() => {
		if (routeDict === "dictionary") {
			let cloneValues = lod_.cloneDeep(values);

			if (values.isDynamic === false) {
				lod_.set(cloneValues, "whitelistDynamic", {});
			}
			setValues(cloneValues);
		}
	}, [values?.isDynamic]);

	const close = (e, reason) => {
		handleCloseDialog();
	};

	useEffect(() => {
		if (routeDict === "dictionary" && (values?.type === "code" || values?.type === "codeImage")) {
			let cloneValues = lod_.cloneDeep(values);
			if (!lod_.isEmpty(listCodes)) {
				cloneValues.codes = listCodes;
			} else {
				cloneValues.codes = {};
			}
			setValues(cloneValues);
		}
	}, [listCodes]);

	/**
	 * Send form
	 */
	const submit = () => {
		handleSave(
			values,
			customUnique,
			callback => {
				setLoadingValidate(false);
			},
			rolesProfileUser
		);
	};

	/**
	 * If u have PI in form and u click to show them
	 */
	const setPIForm = () => {
		setConfirmHasPI(false);
		const onSuccess = res => {
			setValues(res.valueDictionary);
			setFormDictionary(res.formBuilderDictionary);
			setPI(true);
		};
		if (target) {
			dispatch(
				FormActions.getItemByID(
					selectedId,
					route?.form,
					target,
					routeDict,
					onSuccess,
					true,
					flagAccount
				)
			);
		}
	};

	const setOpenConfirmPI = () => {
		if (values?.hasPIValue) {
			setConfirmHasPI(true);
		} else {
			setPIForm();
		}
	};

	const handleClosePI = () => {
		setConfirmHasPI(false);
	};

	useEffect(() => {
		if (forceEdit && selectedId) {
			setOpenConfirmPI();
		}
	}, [forceEdit]);

	/** Condition to open right pannel code */
	const openPannelCode = () => {
		return routeDict === "dictionary" && (values?.type === "code" || values?.type === "codeImage");
	};

	/** Condition to open right pannel ACCESS for resource */
	const openPannelAccess = () => {
		return routeDict === "resource";
	};

	/** Condition to open right pannel ACCESS for API */
	const openPannelApiAccess = () => {
		return routeDict === "faibrikApiAccess";
	};

	/** Condition to open right pannel dynamic list */
	const openPannelDynamicList = () => {
		return routeDict === "dictionary" && values?.isDynamic === true;
	};

	const openPannelDictionary = () => {
		return routeDict === "dictionary" && values?.type === "dictionary";
	};

	/** Disable the submit button or not */
	const isButtonDisabled = () => {
		// Check if all codes are valid
		let unvalidCode = listCodes.find(code => code.valid === false);
		if (unvalidCode) {
			return true;
		}

		return false;
	};

	return (
		<Dialog
			fullWidth
			open={open}
			onClose={close}
			maxWidth="md"
			PaperProps={{
				style: {
					height: "90%",
					width: "100%",
					maxWidth: "80%"
				}
			}}
		>
			<Box style={{ display: "flex" }}>
				<DialogTitle>
					{`${isEmpty ? i18n.t("FORMS.LABELS.add") : i18n.t("FORMS.LABELS.update")} ${
						route?.form?.pageLabel ?? route.name
					}`}
				</DialogTitle>
				{!PI && (
					<Tooltip
						title={`${i18n.t("FORMS.edit")} ${route?.form?.pageLabel ?? route.name}`}
						placement="top"
					>
						<IconButton disabled={PI} onClick={() => setOpenConfirmPI()}>
							<EditIcon />
						</IconButton>
					</Tooltip>
				)}
			</Box>
			<DialogContent style={{ display: "flex" }}>
				{confirmHasPI && (
					<Dialog open={confirmHasPI} onClose={handleClosePI}>
						<DialogTitle>{i18n.t("FORMS.showHiddenConfirmTitle")}</DialogTitle>
						<DialogContent>{i18n.t("FORMS.showHiddenConfirm")}</DialogContent>
						<DialogActions>
							<MDButton autoFocus onClick={handleClosePI} variant="outlined" color="info">
								{i18n.t("FORMS.cancel")}
							</MDButton>
							<MDButton onClick={setPIForm} color="info" variant="contained" autoFocus>
								{i18n.t("FORMS.validate")}
							</MDButton>
						</DialogActions>
					</Dialog>
				)}

				<MDBox display="flex" flex="1">
					<MDBox
						flex="2"
						display="flex"
						flexDirection="column"
						width={
							routeDict === "dictionary" &&
							(values?.type === "code" || values?.type === "codeImage" || values?.isDynamic === true)
								? "60%"
								: "100%"
						}
					>
						<OrderFormDictionaryChild
							fieldChild={formDictionary}
							regexEmail={regexEmail}
							listAPIs={listAPIs}
							isEmpty={isEmpty}
							values={values}
							PI={PI}
							formDictionary={formDictionary}
							customErrors={customErrors}
							userLangage={user?.language?.toLowerCase() || "fr"}
							root=""
							contextDictionary={contextDictionary}
							routeDict={routeDict}
							listSkills={listSkills}
							setCustomErrors={setCustomErrors}
							setFormDictionary={setFormDictionary}
							handleInputChange={handleInputChange}
							setHasErrors={setHasErrors}
							setCustomUnique={setCustomUnique}
							customUnique={customUnique}
						/>
						{/*
						 * Pannel for access settings resource
						 */}
						{openPannelAccess() && (
							<AccessResource
								values={values}
								assistantID={profile.assistantID}
								isEmpty={isEmpty}
								setValues={setValues}
							/>
						)}
						{openPannelApiAccess() && (
							<ApiAccess
								values={values}
								form={route?.form}
								assistantID={profile.assistantID}
								isEmpty={isEmpty}
								setValues={setValues}
							/>
						)}
					</MDBox>

					{/*
					 * Right pannel for code and codeImage
					 */}
					{openPannelCode() && <PannelCode listCodes={listCodes} setListCodes={setListCodes} />}
					{/*
					 * Right pannel for dynamic list
					 */}
					{openPannelDynamicList() && (
						<PannelDynamicList
							values={values}
							handleInputChange={handleInputChange}
							listAPIs={listAPIs}
							PI={PI}
						/>
					)}
					{/**
					 * Right pannel link a dictionary
					 */}
					{openPannelDictionary() && (
						<PannelDictionary values={values} handleInputChange={handleInputChange} />
					)}
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={close}>
					{i18n.t("FORMS.cancel")}
				</MDButton>
				<MDButton
					variant="contained"
					color="info"
					onClick={submit}
					disabled={loadingValidate || hasErrors || !PI || isButtonDisabled()}
				>
					{i18n.t("FORMS.validate")}
					{loadingValidate && (
						<Fade in={loadingValidate}>
							<MDBox display="flex" alignItems="center" ml={2}>
								<CircularProgress size={20} color="light" />
							</MDBox>
						</Fade>
					)}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}
