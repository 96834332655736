/* eslint-disable react/jsx-no-duplicate-props */

import lod_ from "lodash";
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Icon,
	IconButton,
	InputAdornment,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography
} from "@mui/material";

import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";
import { t } from "i18next";
import { useEffect, useState } from "react";
import SettingsActions from "redux-react/actions/settingsActions";
import { useDispatch } from "react-redux";
import { display } from "redux-react/reducers/snackBarReducer";
import { v4 as uuidv4 } from "uuid";

/**
 * Pannel to add / edit access from faibrikAccess for resource
 * @param {*} param0
 * @returns
 */
const ApiAccess = ({ assistantID, isEmpty, setValues, values, form }) => {
	const [isSecretID, setIsSecretID] = useState(true);
	const [isSecretClient, setIsSecretClient] = useState(true);
	const [listOfResources, setListOfResources] = useState([]);
	const [listOfResourcesSystem, setListOfResourcesSystem] = useState([]);

	const dispatch = useDispatch();

	const getResources = () => {
		const onSuccess = res => {
			if (
				!lod_.isNil(res?.resources?.resourcesClient) &&
				!lod_.isEmpty(res?.resources?.resourcesClient)
			) {
				setListOfResources(res?.resources?.resourcesClient);
			} else {
				setListOfResources([]);
			}

			if (
				!lod_.isNil(res?.resources?.resourcesSystem) &&
				!lod_.isEmpty(res?.resources?.resourcesSystem)
			) {
				setListOfResourcesSystem(res?.resources?.resourcesSystem);
			} else {
				setListOfResourcesSystem([]);
			}
		};
		const onFailure = res => {
			setListOfResources([]);
			setListOfResourcesSystem([]);
		};
		dispatch(SettingsActions.getResources(assistantID, onSuccess, onFailure));
	};

	useEffect(() => {
		getResources();
		if (isEmpty) {
			let newUUIDClientID = uuidv4();
			let newUUIDClientSecret = uuidv4();

			setValues(prev => {
				let copyValue = lod_.cloneDeep(prev);
				copyValue.clientId = newUUIDClientID;
				copyValue.clientSecret = newUUIDClientSecret;
				return copyValue;
			});
		}
	}, []);

	const copyValue = input => {
		let valueCopy;
		switch (input) {
			case "id": {
				valueCopy = values.clientId;
				break;
			}
			case "secret": {
				valueCopy = values.clientSecret;
				break;
			}
			default:
				break;
		}
		navigator.clipboard.writeText(valueCopy);
		dispatch(
			display({
				message: t("FORMS.copyToClipboard"),
				type: "success"
			})
		);
	};

	const getInputStyle = input => {
		switch (input) {
			case "id": {
				if (isSecretID) {
					return {
						color: "transparent",
						textShadow: "0 0 5px rgba(0,0,0,0.5)"
					};
				}
				break;
			}
			case "secret": {
				if (isSecretClient) {
					return {
						color: "transparent",
						textShadow: "0 0 5px rgba(0,0,0,0.5)"
					};
				}
				break;
			}
			default:
				return {};
		}
		return {};
	};

	const resetSecretInput = input => {
		let newUUID = uuidv4();

		switch (input) {
			case "id": {
				setValues(prev => {
					let copyValue = lod_.cloneDeep(prev);
					copyValue.clientId = newUUID;
					return copyValue;
				});
				break;
			}
			case "secret": {
				setValues(prev => {
					let copyValue = lod_.cloneDeep(prev);
					copyValue.clientSecret = newUUID;
					return copyValue;
				});
				break;
			}
			default:
				break;
		}
	};

	const showClientInput = () => {
		setIsSecretClient(false);
	};

	const showIDInput = () => {
		setIsSecretID(false);
	};

	const checkCheckboxValue = (input, code) => {
		if (lod_.isNil(values?.scope)) {
			setValues(prev => {
				let copyValue = lod_.cloneDeep(prev);
				copyValue.scope = [];
				return copyValue;
			});
			return false;
		}

		let scopeFound = values?.scope.find(str => str === `${input}:resource:${code}`);
		if (!lod_.isNil(scopeFound)) return true;

		return false;
	};

	const handleSelectScope = (e, input, code) => {
		setValues(prev => {
			let copyValue = lod_.cloneDeep(prev);
			if (e.target.checked === false) {
				copyValue.scope = copyValue.scope.filter(item => item !== `${input}:resource:${code}`);
			} else if (!copyValue.scope.includes(`${input}:resource:${code}`)) {
				copyValue.scope.push(`${input}:resource:${code}`);
			}
			return copyValue;
		});
	};

	return (
		<MDBox display="flex" flexDirection="column" pt={2}>
			<MDBox style={{ display: "flex", flexDirection: "column" }}>
				<MDBox style={{ display: "flex", alignItems: "flex-end" }}>
					<Typography variant="h4" pt={2} pr={1}>
						Clées API
					</Typography>
				</MDBox>

				<MDBox pt={2} style={{ display: "flex" }}>
					<MDBox style={{ width: "100%" }}>
						<MDInput
							className="dialogInput"
							label="CLIENT ID"
							value={values?.clientId || null}
							disabled
							inputProps={{
								style: getInputStyle("id")
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										{isSecretID ? (
											<Tooltip title="Visualiser les clées API">
												<IconButton
													color="info"
													style={{ pointerEvents: "visible" }}
													onClick={() => {
														showIDInput();
													}}
													variant="contained"
												>
													<Icon>visibility</Icon>
												</IconButton>
											</Tooltip>
										) : (
											<MDBox display="flex">
												<MDBox pr={1}>
													<Tooltip title="Regénérer la clée API">
														<IconButton
															color="info"
															style={{ pointerEvents: "visible" }}
															onClick={() => {
																resetSecretInput("id");
															}}
														>
															<Icon>autorenew</Icon>
														</IconButton>
													</Tooltip>
												</MDBox>
												<MDBox>
													<Tooltip title="Copier la clée">
														<IconButton
															color="info"
															style={{ pointerEvents: "visible" }}
															onClick={() => {
																copyValue("id");
															}}
														>
															<Icon>copy</Icon>
														</IconButton>
													</Tooltip>
												</MDBox>
											</MDBox>
										)}
									</InputAdornment>
								)
							}}
							style={{ width: "50%" }}
						/>
					</MDBox>
				</MDBox>

				<MDBox pt={2} style={{ display: "flex" }}>
					<MDBox style={{ width: "100%" }}>
						<MDInput
							className="dialogInput"
							label="CLIENT SECRET"
							value={values?.clientSecret || null}
							disabled
							inputProps={{
								style: getInputStyle("secret")
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										{isSecretClient ? (
											<Tooltip title="Visualiser les clées API">
												<IconButton
													color="info"
													style={{ pointerEvents: "visible" }}
													onClick={() => {
														showClientInput();
													}}
													variant="contained"
												>
													<Icon>visibility</Icon>
												</IconButton>
											</Tooltip>
										) : (
											<MDBox display="flex">
												<MDBox pr={1}>
													<Tooltip title="Regénérer la clée API">
														<IconButton
															color="info"
															style={{ pointerEvents: "visible" }}
															onClick={() => {
																resetSecretInput("secret");
															}}
														>
															<Icon>autorenew</Icon>
														</IconButton>
													</Tooltip>
												</MDBox>
												<MDBox>
													<Tooltip title="Copier la clée">
														<IconButton
															color="info"
															style={{ pointerEvents: "visible" }}
															onClick={() => {
																copyValue("secret");
															}}
														>
															<Icon>copy</Icon>
														</IconButton>
													</Tooltip>
												</MDBox>
											</MDBox>
										)}
									</InputAdornment>
								)
							}}
							style={{ width: "50%" }}
						/>
					</MDBox>
				</MDBox>

				<MDBox style={{ display: "flex", flexDirection: "column", width: "100%" }}>
					<MDBox pr={1} style={{ width: "50%" }}>
						<Typography variant="h4" pt={2} pr={1} pb={1}>
							Autorisations ressources client
						</Typography>

						<TableContainer component={Paper} style={{ overflow: "auto", maxHeight: "100%" }}>
							<Table aria-label="responsive table">
								<TableRow>
									<TableCell
										style={{
											width: "40%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										<Typography variant="h6">Ressources</Typography>
									</TableCell>
									<TableCell
										align="center"
										style={{
											width: "20%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										<Typography variant="h6">Lecture</Typography>
									</TableCell>
									<TableCell
										align="center"
										style={{
											width: "20%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										<Typography variant="h6">Ecriture</Typography>
									</TableCell>
									<TableCell
										align="center"
										style={{
											width: "20%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										<Typography variant="h6">Suppression</Typography>
									</TableCell>
								</TableRow>

								<TableBody>
									{!lod_.isNil(listOfResources) &&
										listOfResources
											.sort((a, b) => a.name.localeCompare(b.name))
											.map(row => (
												<TableRow key={row.code}>
													<TableCell
														component="th"
														scope="row"
														style={{ whiteSpace: "normal", wordBreak: "break-word" }}
													>
														{row.name}
													</TableCell>
													<TableCell align="center" style={{ width: "20%" }}>
														<Checkbox
															checked={checkCheckboxValue("read", row.code)}
															onClick={e => {
																handleSelectScope(e, "read", row.code);
															}}
														/>
													</TableCell>
													<TableCell align="center" style={{ width: "20%" }}>
														<Checkbox
															checked={checkCheckboxValue("write", row.code)}
															onClick={e => {
																handleSelectScope(e, "write", row.code);
															}}
														/>
													</TableCell>
													<TableCell align="center" style={{ width: "20%" }}>
														<Checkbox
															checked={checkCheckboxValue("delete", row.code)}
															onClick={e => {
																handleSelectScope(e, "delete", row.code);
															}}
														/>
													</TableCell>
												</TableRow>
											))}
								</TableBody>
							</Table>
						</TableContainer>
					</MDBox>

					<MDBox pb={1} style={{ width: "50%" }}>
						<Typography variant="h4" pt={2} pr={1} pb={1}>
							Autorisations ressources système
						</Typography>

						<TableContainer component={Paper} style={{ overflow: "auto", maxHeight: "100%" }}>
							<Table aria-label="tableau restreint">
								<TableRow>
									<TableCell
										style={{
											width: "40%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										<Typography variant="h6">Ressources</Typography>
									</TableCell>
									<TableCell
										align="center"
										style={{
											width: "20%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										<Typography variant="h6">Lecture</Typography>
									</TableCell>
									<TableCell
										align="center"
										style={{
											width: "20%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										<Typography variant="h6"></Typography>
									</TableCell>
									<TableCell
										align="center"
										style={{
											width: "20%",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										<Typography variant="h6"></Typography>
									</TableCell>
								</TableRow>
								<TableBody>
									{!lod_.isNil(listOfResourcesSystem) &&
										listOfResourcesSystem
											.sort((a, b) => a.name.localeCompare(b.name))
											.map(row => (
												<TableRow key={row.code}>
													<TableCell
														component="th"
														scope="row"
														style={{ whiteSpace: "normal", wordBreak: "break-word" }}
													>
														{row.name}
													</TableCell>
													<TableCell align="center" style={{ width: "20%" }}>
														<Checkbox
															checked={checkCheckboxValue("read", row.code)}
															onClick={e => {
																handleSelectScope(e, "read", row.code);
															}}
														/>
													</TableCell>
													<TableCell align="center" style={{ width: "20%" }}></TableCell>
													<TableCell align="center" style={{ width: "20%" }}></TableCell>
												</TableRow>
											))}
								</TableBody>
							</Table>
						</TableContainer>
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default ApiAccess;
