/* eslint-disable object-shorthand */
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	InputAdornment,
	Tooltip
} from "@mui/material";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import FormAction from "redux-react/actions/formAction";
import { useState } from "react";
import { useDispatch } from "react-redux";
import MDButton from "components/Basics/MDButton";
import { t } from "i18next";
import MappingDialog from "./components/MappingDialog";

const InputMapping = ({
	label,
	disabled = false,
	value,
	onChange,
	item = {},
	path = "",
	listOfApis = [],
	error,
	...rest
}) => {
	const config = item.config || {
		dictionariesInput: [],
		dictionariesOutput: []
	};

	const [openDialog, setOpenDialog] = useState(false);

	const onCloseDialog = () => {
		setOpenDialog(false);
	};

	return (
		<MDBox>
			<MDTypography variant="caption" mb={1}>
				{label}
			</MDTypography>
			<MDBox
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				mb={1}
			>
				<MDInput
					disabled
					fullWidth
					value={value}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Tooltip placement="top" title={t("SETTINGS.DICTIONARY.copyToClipboard")}>
									<IconButton
										color="info"
										style={{ pointerEvents: "visible" }}
										onClick={() => {
											navigator.clipboard.writeText(value);
										}}
									>
										<Icon>copy</Icon>
									</IconButton>
								</Tooltip>
							</InputAdornment>
						)
					}}
				/>
				<MDButton
					sx={{ ml: 1 }}
					variant="contained"
					color="info"
					onClick={() => setOpenDialog(true)}
				>
					{t("SETTINGS.edit")}
				</MDButton>
			</MDBox>
			<MappingDialog
				open={openDialog}
				onClose={onCloseDialog}
				mapping={value}
				config={config}
				onChange={onChange}
			/>
		</MDBox>
	);
};

export default InputMapping;
