/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import "./style.css";
import lod_ from "lodash";
import { v4 } from "uuid";

import { Icon, IconButton, InputAdornment, TableCell, Tooltip } from "@mui/material";
import MDBox from "components/Basics/MDBox";
import { queryDictionaries } from "helpers/dictionary";
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import { use, useEffect, useRef, useState } from "react";
import { socket } from "redux-react/middleware/ws";
import { useDispatch } from "react-redux";
import { display } from "redux-react/reducers/snackBarReducer";

import { t } from "i18next";
import MDInput from "components/Basics/MDInput";
import MDButton from "components/Basics/MDButton";
import RowMapping from "./rowMapping";

const MappingContent = ({ open, mapping: mappingPropsString, config, handleChangeMapping }) => {
	const dispatch = useDispatch();

	const [dictionaries, setDictionaries] = useState({});

	const [mapping, setMapping] = useState({});

	const bottom = useRef(null);

	/**
	 * List all possible path from mapping object, like: "value1.path2.path3", "value1.path2.path4", ...
	 */
	function getAllPaths(obj, currentPath = "") {
		return lod_.flatMap(lod_.keys(obj), key => {
			const newPath = currentPath ? `${currentPath}.${key}` : key;
			if (lod_.isObject(obj[key]) && !lod_.isArray(obj[key])) {
				return getAllPaths(obj[key], newPath);
			}
			return newPath;
		});
	}

	const onChangePath = (oldPath, newPath, value) => {
		setMapping(prev => {
			const newMapping = lod_.cloneDeep(prev);

			if (oldPath === newPath) {
				return newMapping;
			}

			lod_.unset(newMapping, oldPath);

			if (!lod_.has(newMapping, newPath)) {
				lod_.set(newMapping, newPath, value);
			} else {
				lod_.set(newMapping, `${newPath}.${v4()}`, value);
			}

			return newMapping;
		});
	};
	const onChangeValue = (path, value) => {
		setMapping(prev => {
			const newMapping = lod_.cloneDeep(prev);
			lod_.set(newMapping, path, value);

			return newMapping;
		});
	};

	const removeEmptyObjects = obj => {
		if (lod_.isObject(obj) && !lod_.isArray(obj)) {
			const filtered = lod_.mapValues(obj, removeEmptyObjects);
			let res = lod_.omitBy(filtered, e => {
				return lod_.isObject(e) && lod_.isEmpty(e);
			});
			return res;
		}
		return obj;
	};

	const deleteRow = path => {
		setMapping(prev => {
			const newMapping = lod_.cloneDeep(prev);
			lod_.unset(newMapping, path);
			return removeEmptyObjects(newMapping);
		});
	};

	// all values in leafs must be replaced by {{{ json <originvalue> }}}
	const getHandlerbarMapping = () => {
		const mappingCopy = lod_.cloneDeep(mapping);
		const paths = getAllPaths(mappingCopy);

		let stringifiedMapping = JSON.stringify(lod_.cloneDeep(mappingCopy));

		stringifiedMapping = stringifiedMapping.replaceAll("}", " }");

		for (let path of paths) {
			const value = lod_.get(mappingCopy, path);

			stringifiedMapping = stringifiedMapping.replace(`:"${value}"`, `:{{{ json ${value} }}} `);
		}

		return stringifiedMapping;
	};

	const getDictionaries = async () => {
		const dictionariesList = await queryDictionaries(dispatch, config.dictionariesInput);
		setDictionaries(dictionariesList);
	};

	const initMappingObject = obj => {
		try {
			obj = obj.replaceAll("{{{ json ", '"');
			obj = obj.replaceAll(" }}}", '"');
			let mappingObject = JSON.parse(obj);
			return mappingObject;
		} catch (e) {
			dispatch(display({ type: "error", message: "Mapping mal formé, impossible de l'ouvrir" }));
			return {};
		}
	};

	useEffect(() => {
		handleChangeMapping(getHandlerbarMapping());
	}, [mapping]);

	useEffect(() => {
		getDictionaries();
		if (!lod_.isEmpty(mappingPropsString)) {
			setMapping(initMappingObject(mappingPropsString));
		}
	}, []);

	return (
		<MDBox mt={2} bgColor="white" p={1} borderRadius="md" display="flex" flexDirection="column">
			<MDBox display="flex" flexDirection="column" alignItems="center" sx={{ mt: 1 }}>
				<MDBox
					display="flex"
					flexDirection="row"
					alignItems="center"
					flex="1"
					sx={{ width: "100%" }}
					style={{
						backgroundColor: "white",
						position: "sticky",
						zIndex: 2,
						top: 0
					}}
					pb={1}
				>
					<MDInput
						disabled
						fullWidth
						value={getHandlerbarMapping()}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Tooltip placement="top" title={t("SETTINGS.DICTIONARY.copyToClipboard")}>
										<IconButton
											color="info"
											style={{ pointerEvents: "visible" }}
											onClick={() => {
												navigator.clipboard.writeText(getHandlerbarMapping());
											}}
										>
											<Icon>copy</Icon>
										</IconButton>
									</Tooltip>
								</InputAdornment>
							)
						}}
					/>
					<MDButton
						sx={{ ml: 1 }}
						variant="contained"
						color="info"
						onClick={() => {
							setMapping(prev => {
								return {
									...prev,
									"": ""
								};
							});
							setTimeout(() => {
								bottom.current.scrollIntoView({ behavior: "smooth" });
							}, 100);
						}}
					>
						{t("SETTINGS.add")}
					</MDButton>
				</MDBox>
				{/*  */}
				{getAllPaths(mapping).map((path, index) => {
					return (
						<RowMapping
							key={index}
							dictionaries={dictionaries}
							path={path}
							value={lod_.get(mapping, path)}
							onChangePath={onChangePath}
							onChangeValue={onChangeValue}
							deleteRow={deleteRow}
						/>
					);
				})}
				<div ref={bottom}></div>
			</MDBox>
		</MDBox>
	);
};

export default MappingContent;
